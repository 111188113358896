import React from 'react';
import Anchor from 'components/ui/anchor';
import { createList, slugify } from 'utils/utilFunctions';
import { CategroiesWrap } from './categories.style';

const Categories = ({ categories, ...restProps }) => {
  const catList = createList({
    list: categories,
  });
  return (
    <CategroiesWrap {...restProps}>
      {catList.map((cat) => (
        <Anchor
          path={`/news/categories/${slugify(cat.text.title)}`}
          key={slugify(cat.text.title)}
        >
          #{cat.text.title}
        </Anchor>
      ))}
    </CategroiesWrap>
  );
};

export default Categories;
