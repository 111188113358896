import React from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'containers/layout/layout';
import Header from 'containers/layout/header';
import Footer from 'containers/layout/footer';
import { Container } from 'components/ui/wrapper';
import SEO from 'components/seo';
import Heading from 'components/ui/heading';
import Image from 'components/image';
import Categories from 'components/blog/categories';
import { BlogContentsContainer } from './blog-template.style';
import BackButton from 'components/ui/back-button';
import Anchor from 'components/ui/anchor';
import Subtitle from 'components/ui/subtitle';
import SocialShare from 'components/blog/social-share';
import { slugify } from 'utils/utilFunctions';

const BlogTemplate = ({ data, location, ...restProps }) => {
  const post = data.stories.edges[0].node;
  if (data.avatar.edges.length > 0) {
    post.author.image = data.avatar.edges[0].node.image;
  }

  const { categories, author } = post;
  const { postTitleStyle, authorNameStyle } = restProps;

  return (
    <Layout location={location}>
      <Header fixedDisplayVarient={true} />
      <SEO title={post.title} image={post.photo.childImageSharp.fluid.src} pathname={'/' + post.slug} />
      <main className="site-wrapper-reveal">
        <BlogContentsContainer className="left-red-slice md-no-bg-image">
          <Container className="pb-5">
            <Image fluid={post.photo.childImageSharp.fluid} alt={post.title} />
            <Heading {...postTitleStyle}>{post.title}</Heading>
            {post.postedDate && <Subtitle>{post.postedDate}</Subtitle>}
            <ReactMarkdown>{post.body}</ReactMarkdown>
            <Anchor path={`/news/authors/${slugify(author.name)}`}>
              <Heading style={{ marginTop: '30px' }} {...authorNameStyle}>{author.name}</Heading>
            </Anchor>
            <Categories categories={categories} />
            <SocialShare title={post.title} url={post.slug} />
            <BackButton />
          </Container>
        </BlogContentsContainer>
      </main>
      <Footer />
    </Layout>
  );
};

BlogTemplate.propTypes = {
  sectionStyle: PropTypes.object,
};

BlogTemplate.defaultProps = {
  pageTitleStyle: {
    as: 'h1',
    m: '50px 0',
  },
  postTitleStyle: {
    m: '40px 0 20px',
    as: 'h3',
  },
  authorNameStyle: {
    as: 'h2',
  },
  socialStyles: {
    fontSize: '45px',
    lineHeight: 1,
  },
};

export const postQuery = graphql`
  query($id: String, $authorId: String!) {
    stories: allStoriesJson(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          title
          slug
          body
          postedDate(formatString: "LL")
          author {
            id
            name
          }
          categories {
            title
          }
          photo {
            childImageSharp {
              fluid(maxWidth: 1170, maxHeight: 450, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
                presentationHeight
                aspectRatio
              }
            }
          }
        }
      }
    }
    avatar: allAuthorsJson(filter: { id: { eq: $authorId } }) {
      edges {
        node {
          image {
            childImageSharp {
              fluid(maxWidth: 100, maxHeight: 100, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
                presentationHeight
              }
            }
          }
        }
      }
    }
  }
`;

export default BlogTemplate;
