import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MdShare } from 'react-icons/md';
import { TiSocialTwitter, TiSocialLinkedin } from 'react-icons/ti';
import Social, { SocialLink } from 'components/ui/social';
import Text from 'components/ui/text';
import {
  SocialShareWrap,
  SocialMediaWrap,
  FloatingSocialBox,
  StyledButton,
} from './social-share.style';

const SocialShare = ({ socialStyle, socialButtonStyle, title, url }) => {
  const [socialOpen, setSocialOpen] = useState(false);
  const baseUrl = 'https://www.atbventures.com/news/';
  const socialHandler = () => {
    setSocialOpen((prev) => !prev);
  };
  return (
    <SocialShareWrap className="social-share">
      <Text as="span" className="share-label">
        Share this post
      </Text>
      <SocialMediaWrap>
        <StyledButton {...socialButtonStyle} onClick={socialHandler}>
          <MdShare />
        </StyledButton>
        <FloatingSocialBox isOpen={socialOpen}>
          <Social {...socialStyle}>
            <SocialLink
              path={`https://twitter.com/intent/tweet?text=${title}&url=${baseUrl +
                url}`}
              label="Twitter"
            >
              <TiSocialTwitter />
            </SocialLink>
            <SocialLink
              path={`https://www.linkedin.com/shareArticle?mini=true&url=${baseUrl +
                url}&title=${title}`}
              label="Linkedin"
            >
              <TiSocialLinkedin />
            </SocialLink>
          </Social>
        </FloatingSocialBox>
      </SocialMediaWrap>
    </SocialShareWrap>
  );
};

SocialShare.propTypes = {
  socialButtonStyle: PropTypes.object,
  socialStyle: PropTypes.object,
};

SocialShare.defaultProps = {
  socialButtonStyle: {
    varient: 'onlyIcon',
    width: '48px',
    shape: 'bullet',
    hover: 'false',
  },
  socialStyle: {
    fontSize: '12px',
    color: '#555',
    space: 14,
  },
};

export default SocialShare;
