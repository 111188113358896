import styled from 'styled-components';
import { device } from 'theme';

export const SocialShareWrapper = styled.div`
  margin: 0 0 65px;
`;

export const BlogContentsContainer = styled.div`
  padding: 100px;
  @media ${device.medium} {
    padding: 0px;
  }
`;
